import UpRightArrow from '../Images/UpRightArrowColor.svg'
import BigEhter from '../Images/LandingEth/BigEhter.svg'
import BehindCoin from '../Images/LandingEth/BehindCoin.svg'
import BigSingleCoin from '../Images/LandingEth/BigSingleCoin.svg'
import MediumSingleCoin from '../Images/LandingEth/MediumSingleCoin.svg'
import LeftPileOfCoins from '../Images/LandingEth/LeftPileOfCoins.svg'
import RightPileOfCoins from'../Images/LandingEth/RightPileOfCoins.svg'

function LandingPage() {
    return (
        <div class="landingPageMainContainer" id="landingPageComponentTargetElement">
            <div class="landingPageRowContainer">
                <div class="landingPageRowContainerLeftColumn">
                    <h1 class="landingPageMainHeader"><span class="landingPageMainHeaderSpan">Your Web3 Partner For Exceptional Security</span></h1>
                    <h1 class="landingPageDescriptionText">
                        Embark on a journey with our dedicated team of security experts, committed to fortifying your Web3 defenses.
                        We ensure your protocol stands resilient in the ever-evolving decentralized landscape.
                    </h1>
                    <div class="landignPageRowButtons">
                        <a href="https://t.me/dimulski" target="_blank" rel="dimulski telegram" class="landingPageLinkStyle">
                            <button class="landignPageContactButton">
                                <h1 class="landingPageContactButtonText">Book an audit</h1>
                            </button>
                        </a>

                        <a href="https://github.com/AretePrime/Audits" target="_blank" rel="AretePrime portfolio" class="landingPageLinkStyle">
                            <button class="landingPagePortfolioButton">
                                <div class="landingPagePortfolioTextContainer">
                                    <h1 class="landingPagePortfolioTextHeader">Portfolio</h1>
                                </div>
                                <div class="landingPageArrowContainer">
                                    <img class="ladningPageArrow" src={UpRightArrow} />
                                </div>
                            </button>
                        </a>

                    </div>
                    {/* <h1 class="landingPageSmallesHeader"> Helped prevent > 50 bugs</h1> */}
                </div>

                <div class="landingPageRowContainerRightColumn">
                    <img class="landingPageBigEtherStyles" src={BigEhter} />
                    <img class="landingPageBehindCoinStyles" src={BehindCoin} />
                    <img class="landingPageBigSingleCoinStyles" src={BigSingleCoin} />
                    <img class="landingPageMediumSingleCoinStyles" src={MediumSingleCoin} />
                </div>
            </div>
        </div>
    )
}
export default LandingPage;