import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import VaultodyOnlyLogo from '../Images/VaultodyOnlyLogo.svg'

function Reviews() {
    return (
        <div class="reviewsContainer" id="reviewsComponentTargetElement">
            <div class="reviewsLeftColumnContainer">
                <h1 class="reviewsMainHeader"><span class="landingPageMainHeaderSpan">What protocols have to say about AretePrime</span></h1>
            </div>

            <div class="reviewsRightColumnContainer">
            <div className="swiperContainerBox">
                <div className="swiperSmallContainer">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={20}
                        loop={true}                      
                        grabCursor={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        modules={[Autoplay, Navigation]}
                        navigation={{ prevEl: ".arrowLeft", nextEl: ".arrowRight" }}
                        className="mySwiper"
                        breakpoints={{
                            // when window width is <= 480px
                            320: {
                                spaceBetween: 20,
                                slidesPerView: 1
                            },
                            1024: {
                                spaceBetween: 20,
                                slidesPerView: 1
                            }
                        }}
                    >
                        <SwiperSlide>
                            <div class="reviewsCardContainer">
                                <FontAwesomeIcon class ="reviewsQuotesIcon" icon={faQuoteLeft} />
                                <h1 class="reviewsActualReviewText">
                                    AretePrime consistently demonstrated a strong focus on security, attention to detail, and a commitment to better coding practices.
                                    They excelled in identifying vulnerabilities and providing actionable recommendations to enhance the security of smart contracts.
                                    Their professionalism, responsiveness, and on-time delivery of high-quality work make 
                                    them highly recommended for smart contract security auditing.
                                </h1>
                                <div class="reviewNameAndTwitterHandleContainer">
                                    <div class="reviewNameAndTwitterHandleContainerLeftColumn">
                                        <h1 class="reviewNameAndTwitterHandleContainerLeftColumnName">CEO, Vaultody</h1>
                                        <a href="https://twitter.com/KhatibNashwan" target="_blank" rel="Vaultody owner twitter" class="reviewsCompanyRepresentativeLink">
                                            <h1 class="reviewNameAndTwitterHandleContainerLeftColumnHandle">@KhatibNashwan</h1>
                                        </a>
                                    </div>
                                    <div class="reviewNameAndTwitterHandleContainerRightColumn">
                                        <a href="https://twitter.com/Vaultody" target="_blank" rel="Vaultody twitter" class="reviewsCompanyLogoLink">
                                            <img class="reviewsCompanyLogoImg" src={VaultodyOnlyLogo} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/* <SwiperSlide>
                            <div class="reviewsCardContainer">
                                <FontAwesomeIcon class ="reviewsQuotesIcon" icon={faQuoteLeft} />
                                <h1 class="reviewsActualReviewText">
                                    AretePrime consistently demonstrated a strong focus on security, attention to detail, and a commitment to better coding practices.
                                    They excelled in identifying vulnerabilities and providing actionable recommendations to enhance the security of smart contracts.
                                    Their professionalism, responsiveness, and on-time delivery of high-quality work make 
                                    them highly recommended for smart contract security auditing.
                                </h1>
                                <div class="reviewNameAndTwitterHandleContainer">
                                    <div class="reviewNameAndTwitterHandleContainerLeftColumn">
                                        <h1 class="reviewNameAndTwitterHandleContainerLeftColumnName">CEO, Vaultody</h1>
                                        <a href="https://twitter.com/KhatibNashwan" target="_blank" rel="Vaultody owner twitter" class="reviewsCompanyRepresentativeLink">
                                            <h1 class="reviewNameAndTwitterHandleContainerLeftColumnHandle">@KhatibNashwan</h1>
                                        </a>
                                    </div>
                                    <div class="reviewNameAndTwitterHandleContainerRightColumn">
                                        <a href="https://twitter.com/Vaultody" target="_blank" rel="Vaultody twitter" class="reviewsCompanyLogoLink">
                                            <img class="reviewsCompanyLogoImg" src={VaultodyOnlyLogo} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> */}
                        {/* <SwiperSlide>
                            <div class="reviewsCardContainer">
                                <FontAwesomeIcon class ="reviewsQuotesIcon" icon={faQuoteLeft} />
                                <h1 class="reviewsActualReviewText">
                                    Kudos to Dimulski who figured out out complex architecture, 
                                    was positevily suprised with the speed and quality of the audit.
                                </h1>
                                <div class="reviewNameAndTwitterHandleContainer">
                                    <div class="reviewNameAndTwitterHandleContainerLeftColumn">
                                        <h1 class="reviewNameAndTwitterHandleContainerLeftColumnName">Representative, Company Name</h1>
                                        <h1 class="reviewNameAndTwitterHandleContainerLeftColumnHandle">@twitterhandle</h1>
                                    </div>
                                    <div class="reviewNameAndTwitterHandleContainerRightColumn">
                                        <img class="reviewsCompanyLogoImg" src={EthLogo} />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div class="reviewsCardContainer">
                                <FontAwesomeIcon class ="reviewsQuotesIcon" icon={faQuoteLeft} />
                                <h1 class="reviewsActualReviewText">
                                    Kudos to Dimulski who figured out out complex architecture, 
                                    was positevily suprised with the speed and quality of the audit.
                                </h1>
                                <div class="reviewNameAndTwitterHandleContainer">
                                    <div class="reviewNameAndTwitterHandleContainerLeftColumn">
                                        <h1 class="reviewNameAndTwitterHandleContainerLeftColumnName">Representative, Company Name</h1>
                                        <h1 class="reviewNameAndTwitterHandleContainerLeftColumnHandle">@twitterhandle</h1>
                                    </div>
                                    <div class="reviewNameAndTwitterHandleContainerRightColumn">
                                        <img class="reviewsCompanyLogoImg" src={EthLogo} />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> */}
                    </Swiper>
                </div>
                {/* <button className="arrowLeft swipperArrow">
                    <FontAwesomeIcon class ="reviewsAngleLeftIcon" icon={faAngleLeft} />
                </button>
                <button className="arrowRight swipperArrow">
                    <FontAwesomeIcon class ="reviewsAngleRightIcon" icon={faAngleRight} />
                </button> */}
            </div>
        </div>

            
        </div>
    )
}
export default Reviews;