import Telegram from '../Images/TelegramColor.svg'
import Twitter from '../Images/TwitterColor.svg'
import Llinkedin from '../Images/linkedinLogo.svg'
import ScrollTo from '../Helpers/ScrollTo.js'
import UpRightArrow from '../Images/UpRightArrowColor.svg'

function Footer() {
    return (
        <div class="footerMainContainer">
            <div class="footerContainer">   
                <div class="footerContainerFirstRow">
                    <div class="footerFirstRowLeftColumn">
                        <div class="footerTelegramIconContainer">
                            <a href="https://twitter.com/dimulskiatanas" target="_blank" rel="dimulski twitter" class="footerIconLinkStyles">
                                <img class="footerTelegramIconImg" src={Twitter} />
                            </a>
                        </div>
                        
                        <div class="footerTelegramIconContainer">
                            <a href="https://www.linkedin.com/in/atanas-dimulski-9bb628195/" target="_blank" rel="dimulski linkedin" class="footerIconLinkStyles">
                                <img class="footerTelegramIconImg" src={Llinkedin} />
                            </a>
                        </div>

                        <div class="footerTelegramIconContainer">
                            <a href="https://t.me/dimulski" target="_blank" rel="dimulski telegram" class="footerIconLinkStyles">
                                <img class="footerTelegramIconImg" src={Telegram} />
                            </a>
                        </div>
                    </div>


                </div>
                
                <div class="footerMainContainerBottomRow">
                    <div class="footerRowLeftColumn">
                        <button class="footerIndividualLinksButton"
                            onClick={() => ScrollTo('landingPageComponentTargetElement', 0)}
                        >
                            <h1 class="footerPagesLinkHeader">Home</h1>
                        </button>

                        <button class="footerIndividualLinksButton"
                            onClick={() => ScrollTo('servicesComponentTargetElement', 42)}
                        >
                            <h1 class="footerPagesLinkHeader">Services</h1>
                        </button>

                        <button class="footerIndividualLinksButton"
                            onClick={() => ScrollTo('workComponentTargetElement' , 45)}
                        >
                            <h1 class="footerPagesLinkHeader">Work</h1>
                        </button>

                        <button class="footerIndividualLinksButton"
                            onClick={() => ScrollTo('whyMeComponentTargetElement' , 45)}
                        >
                            <h1 class="footerPagesLinkHeader">About</h1>
                        </button>

                        <button class="footerIndividualLinksButton"
                            onClick={() => ScrollTo('reviewsComponentTargetElement' , 45)}
                        >
                            <h1 class="footerPagesLinkHeader">Reviews</h1>
                        </button>
                    </div>

                    {/* <hr class="footerHRVertical"></hr>   */}

                    <div class="footerFirstRowRightColumn">
                        <a href="https://medium.com/me/stories/public" target="_blank" rel="dimulski medium articles" class="footerLinkStyleOuter">
                            <button class="footerIndividualLinksButtonOutter">
                                <div class="footerPortfolioTextContainer">
                                    <h1 class="footerPagesLinkHeaderOutter">Articles</h1>
                                </div>
                                <div class="footerArrowMainContainer">
                                    <div class="footerArrowContainer">
                                        <img class="footerArrow" src={UpRightArrow} />
                                    </div>
                                </div>
                            </button>
                        </a>

                        <a href="https://dimulski.info/" target="_blank" rel="dimulski find audit" class="footerLinkStyleOuter">
                                <button class="footerIndividualLinksButtonOutter">
                                    <div class="footerPortfolioTextContainer">
                                        <h1 class="footerPagesLinkHeaderOutter">Portfolio</h1>
                                    </div>
                                    <div class="footerArrowMainContainer">
                                        <div class="footerArrowContainer">
                                            <img class="footerArrow" src={UpRightArrow} />
                                        </div>
                                    </div>
                                </button>
                        </a>
                    </div>

                    <div class="footerContainerMainRightColumn">
                        <h1 class="footerAllRightsReserved">
                            <span class="landingPageMainHeaderSpan">© 2024 AretePrime</span>
                        </h1>
                    </div>
                </div>
                <hr class="footerHR"></hr>           
            </div>

            <div class="footerContainerMobile">
                <div class="footerMobileFirstRow">
                    <div class="footerMobileLeftColumn">
                        <h1 class="footerMobileMainHeader">Home</h1>
                        <button class="footerMobileInnerButtons"
                            onClick={() => ScrollTo('landingPageComponentTargetElement', 0)}
                        >
                            <h1 class="footerPagesLinkHeader">Home</h1>
                        </button>

                        <button class="footerMobileInnerButtons"
                            onClick={() => ScrollTo('servicesComponentTargetElement', 42)}
                        >
                            <h1 class="footerPagesLinkHeader">Services</h1>
                        </button>

                        <button class="footerMobileInnerButtons"
                            onClick={() => ScrollTo('workComponentTargetElement' , 45)}
                        >
                            <h1 class="footerPagesLinkHeader">Work</h1>
                        </button>

                        <button class="footerMobileInnerButtons"
                            onClick={() => ScrollTo('whyMeComponentTargetElement' , 45)}
                        >
                            <h1 class="footerPagesLinkHeader">About</h1>
                        </button>

                        <button class="footerMobileInnerButtons"
                            onClick={() => ScrollTo('reviewsComponentTargetElement' , 45)}
                        >
                            <h1 class="footerPagesLinkHeader">Reviews</h1>
                        </button>
                    </div>

                    <div class="footerMobileRightColumn">
                        <h1 class="footerMobileMainHeader">Links</h1>
                        <a href="https://twitter.com/AretePrime" target="_blank" rel="AretePrime twitter" class="footerLinkStyleOuter">
                                <button class="footerIndividualLinksButtonOutter">
                                    <div class="footerPortfolioTextContainer">
                                        <h1 class="footerPagesLinkHeaderOutter">Twitter</h1>
                                    </div>
                                    <div class="footerArrowMainContainer">
                                        <div class="footerArrowContainer">
                                            <img class="footerArrow" src={UpRightArrow} />
                                        </div>
                                    </div>
                                </button>
                        </a>
                        <a href="https://t.me/dimulski" target="_blank" rel="dimulski telegram" class="footerLinkStyleOuter">
                                <button class="footerIndividualLinksButtonOutter">
                                    <div class="footerPortfolioTextContainer">
                                        <h1 class="footerPagesLinkHeaderOutter">Telegram</h1>
                                    </div>
                                    <div class="footerArrowMainContainer">
                                        <div class="footerArrowContainer">
                                            <img class="footerArrow" src={UpRightArrow} />
                                        </div>
                                    </div>
                                </button>
                        </a>
                        <a href="https://medium.com/me/stories/public" target="_blank" rel="AretePrime medium articles" class="footerLinkStyleOuter">
                                <button class="footerIndividualLinksButtonOutter">
                                    <div class="footerPortfolioTextContainer">
                                        <h1 class="footerPagesLinkHeaderOutter">Linkedin</h1>
                                    </div>
                                    <div class="footerArrowMainContainer">
                                        <div class="footerArrowContainer">
                                            <img class="footerArrow" src={UpRightArrow} />
                                        </div>
                                    </div>
                                </button>
                        </a>
                        <a href="https://github.com/AretePrime/Audits" target="_blank" rel="AretePrime portfolio" class="footerLinkStyleOuter">
                                <button class="footerIndividualLinksButtonOutter">
                                    <div class="footerPortfolioTextContainer">
                                        <h1 class="footerPagesLinkHeaderOutter">Portfolio</h1>
                                    </div>
                                    <div class="footerArrowMainContainer">
                                        <div class="footerArrowContainer">
                                            <img class="footerArrow" src={UpRightArrow} />
                                        </div>
                                    </div>
                                </button>
                        </a>
                        <a href="https://medium.com/me/stories/public" target="_blank" rel="AretePrime medium articles" class="footerLinkStyleOuter">
                                <button class="footerIndividualLinksButtonOutter">
                                    <div class="footerPortfolioTextContainer">
                                        <h1 class="footerPagesLinkHeaderOutter">Articles</h1>
                                    </div>
                                    <div class="footerArrowMainContainer">
                                        <div class="footerArrowContainer">
                                            <img class="footerArrow" src={UpRightArrow} />
                                        </div>
                                    </div>
                                </button>
                        </a>
                    </div>
                </div>

                <div class="footerMobileSecondRow">
                    <h1 class="footerMobileAllRightsReserved">
                        <span class="landingPageMainHeaderSpan">© 2024 AretePrime</span>
                    </h1>
                </div>
                <hr class="footerMobileHR"></hr> 
            </div>
        </div>
    )
}
export default Footer;