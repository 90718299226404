
import React, { useRef, useState } from 'react';
import VaultodyLogo from '../Images/VaultodyLogo.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
const Work = () => {

    return (
        <div class="workContainer" id="workComponentTargetElement">
            <div class="workContainerDescriptionContainer">
                <div class="workContainerDescriptionContainerLeftColum">
                    <h1 class="workSectionHeader"><span class="landingPageMainHeaderSpan">Previous work</span></h1>
                </div>

                <div class="workContainerDescriptionContainerRightColum">
                    <h1 class="workSectionDescription">
                        Audits are not silver bullets, yet a meticulously conducted, 
                        high-quality audit significantly mitigates the risk of security breaches.
                    </h1>
                </div>
            </div>

            <div class="workContainerStatsMainContainer">
                <div class="workContainerStatsMainContainerLeftColumn">
                    <div class="workContainerTopRow">
                            <div class="workContainerTopRowHeader">
                                <h1 class="workContainerCardsHeaders">Trusted by the biggest protocols</h1>
                                <div class="workSliderMainContainer">
                                    <Swiper
                                        slidesPerView={3}
                                        spaceBetween={20}
                                        loop={true}                      
                                        grabCursor={true}
                                        autoplay={{
                                            delay: 0,
                                            disableOnInteraction: false,
                                            pauseOnMouseEnter: true,
                                        }}
                                        modules={[Autoplay]}
                                        speed={2000}
                                        breakpoints={{
                                            // when window width is <= 480px
                                            320: {
                                                spaceBetween: 20,
                                                slidesPerView: 2
                                            },
                                            1024: {
                                                spaceBetween: 20,
                                                slidesPerView: 2
                                            },
                                            1220: {
                                                spaceBetween: 20,
                                                slidesPerView: 3 
                                            }
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div class = "workSlideContainer">
                                                <img class="VaultodyLogo" src={VaultodyLogo} />
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div class = "workSlideContainer">
                                                <img class="VaultodyLogo" src={VaultodyLogo} />
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div class = "workSlideContainer">
                                                <img class="VaultodyLogo" src={VaultodyLogo} />
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div class = "workSlideContainer">
                                                <img class="VaultodyLogo" src={VaultodyLogo} />
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div class = "workSlideContainer">
                                                <img class="VaultodyLogo" src={VaultodyLogo} />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div> 
                        </div>
                   
                    <div class="workContainerBottomRow">
                        <div class="workContainerBottomRowLeftColumn">
                            <h1 class="workContainerCardsHeadersBottomColumns">100+</h1>
                            <h1 class="workSectioBottomBoxesDesc">
                                Critical, High & Medium vulnerabilities discovered.
                            </h1>
                        </div>

                        <div class="workContainerBottomRowRightColumn">
                            <h1 class="workContainerCardsHeadersBottomColumns">40+</h1>
                            <h1 class="workSectioBottomBoxesDesc">
                            Projects audited
                            </h1>
                        </div>
                    </div>                    
                </div>

                <div class="workContainerStatsMainContainerRightColumn">
                    <h1 class="workContainerAuditStatsMainHeader">Audit stats</h1>
                    <h1 class="workContainerAuditStatsHeader">May 2024</h1>
                    <div class="workContainerAuditStatsInnerMainContainer">
                        <div class="workContainerAuditStatsInnerMainContainerFirstRow">
                            <div class="workContainerAuditStatsSmallContainer">
                                <div class="workSectionSpanOneContainer">
                                    <div class="workSectionSpanOne"></div>
                                </div>
                                <div class="workSectionHeaderContainer">
                                    <h1 class="workContainerAuditStatsSmallContainerHeader">
                                        1 Audits
                                    </h1>
                                </div>
                            </div>

                            <div class="workContainerAuditStatsSmallContainer">
                                <div class="workSectionSpanOneContainer">
                                    <div class="workSectionSpanTwo"></div>
                                </div>
                                <div class="workSectionHeaderContainer">
                                    <h1 class="workContainerAuditStatsSmallContainerHeader">
                                        300 nSLOC
                                    </h1>
                                </div>
                            </div>
                        </div>

                        <div class="workContainerAuditStatsInnerMainContainerFirstRow">
                            <div class="workContainerAuditStatsSmallContainer">
                                <div class="workSectionSpanOneContainer">
                                    <div class="workSectionSpanThree"></div>
                                </div>
                                <div class="workSectionHeaderContainer">
                                    <h1 class="workContainerAuditStatsSmallContainerHeader">
                                        0 Critical
                                    </h1>
                                </div>
                            </div>

                            <div class="workContainerAuditStatsSmallContainer">
                                <div class="workSectionSpanOneContainer">
                                    <div class="workSectionSpanFour"></div>
                                </div>
                                <div class="workSectionHeaderContainer">
                                    <h1 class="workContainerAuditStatsSmallContainerHeader">
                                        0 High
                                    </h1>
                                </div>
                            </div>
                        </div>

                        <div class="workContainerAuditStatsInnerMainContainerFirstRow">
                            <div class="workContainerAuditStatsSmallContainer">
                                <div class="workSectionSpanOneContainer">
                                    <div class="workSectionSpanFive"></div>
                                </div>
                                <div class="workSectionHeaderContainer">
                                    <h1 class="workContainerAuditStatsSmallContainerHeader">
                                        1 Medium
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Work;